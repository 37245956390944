import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { onlineManager, useQueryClient } from '@tanstack/react-query';

import { IS_OFFLINE } from '@shared/constants';
import { toastSuccess, toastWarning } from '@shared/utils';

function useGetOnlineStatus() {
  const queryClient = useQueryClient();

  useEffect(() => {
    onlineManager.subscribe((isOnline) => {
      if (!isOnline) {
        queryClient.setQueryData([IS_OFFLINE], true);

        toastWarning('Check your internet connection', {
          autoClose: false,
          id: 'offline',
        });
      } else {
        queryClient.setQueryData([IS_OFFLINE], false);
        toast.dismiss('offline');
        toastSuccess('You are back online', { id: 'online' });
      }
    });
  }, [queryClient]);
}

export default useGetOnlineStatus;
