import { URL_REGEX } from './regex';

export const formatTextWithHyperlinks = (content: string) => {
  const words = content.split(' ');
  return words.map((word, index) => {
    if (URL_REGEX.test(word)) {
      return (
        <>
          <a
            href={word}
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            {word}
          </a>
          {index < words.length - 1 ? ' ' : ''}
        </>
      );
    }
    return index < words.length - 1 ? `${word} ` : word;
  });
};
