import { formatLocaleString } from './strings';
import Decimal from 'decimal.js';

const inifinitySign = '\u221E';

// TODO: I think this function can be removed for the formatBigNumber function
const formatNumbersWithK = (amount: number) => {
  if (amount == Number.MAX_SAFE_INTEGER) return inifinitySign;

  if (amount >= 1000) {
    const formattedSupply = (amount / 1000).toFixed(1);
    return formattedSupply.endsWith('.0')
      ? formattedSupply.slice(0, -2) + 'k'
      : formattedSupply + 'k';
  }
  return amount;
};

function removeScientificNotation(value: bigint | number) {
  return value.toLocaleString('fullwide', { useGrouping: false });
}

function isWithinRange(numberOne?: number, numberTwo?: number, range = 0.04) {
  if (!numberOne || !numberTwo) return false;

  return Math.abs(numberOne - numberTwo) <= range;
}

const formatDecimals = (number?: number | string, decimals = 4) => {
  if (!number) return;

  return +parseFloat((+number).toFixed(decimals)).toLocaleString('en-US', {
    useGrouping: false,
    maximumFractionDigits: decimals,
  });
};

const formatDisplayCurrency = (number?: number | string, decimals = 2) => {
  if (!number) return '0.00';

  const formattedWithDecimals = formatDecimals(number, decimals)!;

  return formatLocaleString(formattedWithDecimals);
};

const formatFlexibleDecimals = (
  number: number | string,
  minDecimals = 2,
  maxDecimals = 8
) => {
  if (!number) return '0.00';

  const numericValue = typeof number === 'string' ? parseFloat(number) : number;

  if (numericValue === 0) {
    return `0.${'0'.repeat(minDecimals)}`;
  }

  return numericValue.toLocaleString('en-US', {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
    useGrouping: true,
  });
};

const formatCurrencyWithDecimals = (number: number | string, decimals?: number, unit?: string) => {
  const formatDecimals = decimals || 4;
  const numericValue = typeof number === 'string' ? parseFloat(number) : number;

  if (!numericValue || numericValue === 0) return `${unit ?? ''}0.${'0'.repeat(formatDecimals)}`;
  if (numericValue < 0.0001) {
    return '< 0.0001';
  }
  return formatFlexibleDecimals(numericValue, 4, 4);
};

const countLeadingZeros = (decimalNumber: string) => {
  const decimalPart = decimalNumber.toString().split('.')[1];
  let count = 0;
  let tail = '';

  for (let i = 0; i < decimalPart.length; i++) {
    if (decimalPart[i] === '0') {
      count++;
    } else {
      tail = decimalPart.slice(i);
      break;
    }
  }

  return { leadingZeros: count, tail };
}

type DisplayTokenPrice = (price: string, decimals?: number) => ({ price: string, isShorten: false | { subDecimals: number, tail: string } });

const displayTokenPrice: DisplayTokenPrice = (tokenPrice: string, decimals?: number) => {
  if (!tokenPrice) return { price: '0.00', isShorten: false };
  let price = tokenPrice;
  if (tokenPrice.includes('e')) {
    price = new Decimal(tokenPrice).toFixed(30).replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '');
  }

  const decimalPart = price.toString().split('.')[1];
  const isShorten = decimalPart?.length > (decimals ?? 8);
  if (!isShorten) return {
    price: price,
    isShorten: false
  };
  const leadingZeros = countLeadingZeros(price);
  return {
    price: price,
    isShorten: {
      subDecimals: leadingZeros.leadingZeros,
      tail: leadingZeros.tail,
    }
  }
};

export {
  formatNumbersWithK,
  removeScientificNotation,
  formatDecimals,
  formatDisplayCurrency,
  formatFlexibleDecimals,
  formatCurrencyWithDecimals,
  displayTokenPrice,
  isWithinRange,
};
