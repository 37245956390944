'use client';

import { ReactNode } from 'react';

import { GoogleOAuthProvider } from '@react-oauth/google';

import ProductSubmissionProvider from '@shared/contexts/ProductActionsContext';
import useSetUserTimezone from '@shared/hooks/useSetUserTimeZone';
import NetworkProvider from '@shared/providers/NetworkProvider';
import ReactQueryProvider from '@shared/providers/ReactQueryProvider';
import Web3ModalProvider from '@shared/providers/WagmiProvider';

interface IProps {
  children: ReactNode;
  timeZone?: string;
}

const App = ({ children, timeZone }: IProps) => {
  useSetUserTimezone(timeZone);

  return (
    <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID!}>
      <Web3ModalProvider>
        <ReactQueryProvider>
          <NetworkProvider />
          <ProductSubmissionProvider>{children}</ProductSubmissionProvider>
        </ReactQueryProvider>
      </Web3ModalProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
