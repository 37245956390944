'use client';

import { ReactNode } from 'react';

import { WagmiProvider } from 'wagmi';

import { createWeb3Modal } from '@web3modal/wagmi/react';

import { projectId, wagmiConfig } from '../config/wagmiConfig';

if (!projectId) throw new Error('Project ID is not defined');

createWeb3Modal({
  wagmiConfig: wagmiConfig,
  projectId,
  enableAnalytics: true,
  enableOnramp: false,
  allowUnsupportedChain: true,
  themeVariables: {
    '--w3m-font-family': 'var(--font-exo)',
    '--w3m-color-mix': '#0C111D',
    '--w3m-color-mix-strength': 20,
  },
  allWallets: 'HIDE',
  featuredWalletIds: [
    '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4', // Binance
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0', // Trust wallet
  ],
});

export default function Web3ModalProvider({
  children,
}: {
  children: ReactNode;
}) {
  return <WagmiProvider config={wagmiConfig}>{children}</WagmiProvider>;
}
